import { mq } from '~/lib';

const fontFamilies = {
    regular: '"DINPro", "Arial", "San-Serif"',
    alternative: '"Echo Soul Alt Regular", "Arial", "San-Serif"',
};

export type FontFamily = keyof typeof fontFamilies;

export const serializeFontFamily = (font?: FontFamily) => (font ? fontFamilies[font] : undefined);

// Common weight nameing. See https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
type FontWeightThin = 'Thin' | 'Hairline' | 100;
type FontWeightExtraLight = 'ExtraLight' | 'UltraLight' | 200;
type FontWeightLight = 'Light' | 300;
type FontWeightNormal = 'Normal' | 'Regular' | 400;
type FontWeightMedium = 'Medium' | 500;
type FontWeightDemiBold = 'DemiBold' | 'SemiBold' | 600;
type FontWeightBold = 'Bold' | 700;
type FontWeightExtraBold = 'ExtraBold' | 'UltraBold' | 800;
type FontWeightBlack = 'Black' | 'Heavy' | 900;
type FontWeightExtraBlack = 'ExtraBlack' | 'UltraBlack' | 950;

export type FontWeight =
    | FontWeightThin
    | FontWeightExtraLight
    | FontWeightLight
    | FontWeightNormal
    | FontWeightMedium
    | FontWeightDemiBold
    | FontWeightBold
    | FontWeightExtraBold
    | FontWeightBlack
    | FontWeightExtraBlack;

type BaseTextStyle = {
    fontFamily: keyof typeof fontFamilies;
    fontWeight?: FontWeight;
    fontStyle?: 'normal' | 'italic';
    fontSize: number | string;
    lineHeight?: number | string;
    letterSpacing?: number | string;
    textDecoration?: 'underline' | 'overline' | 'line-through';
    textTransform?: string;
    paddingTop?: number | string;
};

export const serializeFontWeight = (fontWeight?: FontWeight) => {
    if (!fontWeight) return undefined;
    switch (fontWeight) {
        case 'Thin':
        case 'Hairline':
            return 100;
        case 'ExtraLight':
        case 'UltraLight':
            return 200;
        case 'Light':
            return 300;
        case 'Normal':
        case 'Regular':
            return 400;
        case 'Medium':
            return 500;
        case 'DemiBold':
        case 'SemiBold':
            return 600;
        case 'Bold':
            return 700;
        case 'ExtraBold':
        case 'UltraBold':
            return 800;
        case 'Black':
        case 'Heavy':
            return 900;
        case 'ExtraBlack':
        case 'UltraBlack':
            return 950;
        default:
            return fontWeight;
    }
};

const mapTextStyle = ({
    fontFamily,
    fontSize,
    lineHeight,
    fontWeight,
    letterSpacing,
    textTransform,
    paddingTop,
    ...rest
}: Partial<BaseTextStyle>) => ({
    ...rest,
    fontWeight: serializeFontWeight(fontWeight),
    fontFamily: serializeFontFamily(fontFamily),

    textTransform: textTransform,
    paddingTop: paddingTop,
    fontSize: fontSize ? (typeof fontSize === 'string' ? fontSize : `${fontSize}px`) : fontSize,
    lineHeight: lineHeight
        ? typeof lineHeight === 'string'
            ? lineHeight
            : `${lineHeight}px`
        : lineHeight,
    letterSpacing: letterSpacing
        ? typeof letterSpacing === 'string'
            ? letterSpacing
            : `${letterSpacing}px`
        : letterSpacing,
});

type CreateTextStyleArgs<Name extends string> = BaseTextStyle & {
    name: Name;
    tabletStyle?: Partial<BaseTextStyle>;
    mobileStyle?: Partial<BaseTextStyle>;
    xSmallStyle?: Partial<BaseTextStyle>;
};

type TextStyle = {
    desktop: ReturnType<typeof mapTextStyle>;
    tablet: ReturnType<typeof mapTextStyle>;
    mobile: ReturnType<typeof mapTextStyle>;
    xSmall: ReturnType<typeof mapTextStyle>;
};

type TextStyles<Names extends string> = {
    [Name in Names]: TextStyle;
};

const createTextStyles = <Names extends string>(
    ...args: CreateTextStyleArgs<Names>[]
): TextStyles<Names> => {
    return args
        .map(({ mobileStyle: mobile, tabletStyle: tablet, xSmallStyle: xSmall, name, ...rest }) => {
            const baseStyle: BaseTextStyle = {
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: rest.fontSize,
                ...rest,
            };

            const mobileStyle = {
                ...mobile,
            };

            const tabletStyle = {
                ...tablet,
            };

            const xSmallStyle = {
                ...(xSmall ?? mobile),
            };

            return {
                desktop: mapTextStyle(baseStyle),
                tablet: mapTextStyle(tabletStyle),
                mobile: mapTextStyle(mobileStyle),
                xSmall: mapTextStyle(xSmallStyle),
                name,
            };
        })
        .reduce(
            (acc, { name, ...rest }) => ({ ...acc, [name]: { ...rest } }),
            {} as TextStyles<Names>,
        );
};

export const textStyles = createTextStyles(
    {
        name: 'displayGiant',
        fontFamily: 'regular',
        fontWeight: 'Bold',
        textTransform: 'uppercase',
        fontSize: 260,
        lineHeight: 210,
        letterSpacing: -8,
        tabletStyle: {
            fontSize: 160,
            lineHeight: 120,
            letterSpacing: -2,
        },

        mobileStyle: {
            fontSize: 160,
            lineHeight: 120,
            letterSpacing: -2,
        },
        xSmallStyle: {
            fontSize: 80,
            lineHeight: 60,
            letterSpacing: -2,
        },
    },
    {
        name: 'display0',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        textTransform: 'uppercase',
        fontSize: 65,
        lineHeight: 65,
        letterSpacing: 0,
        tabletStyle: {
            fontSize: 55,
            lineHeight: 55,
        },

        mobileStyle: {
            fontSize: 45,
            lineHeight: 45,
        },
    },
    {
        name: 'display1',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        textTransform: 'uppercase',
        fontSize: 45,
        lineHeight: 50,
        letterSpacing: 0,
        tabletStyle: {
            fontSize: 35,
            lineHeight: 40,
        },

        mobileStyle: {
            fontSize: 33,
            lineHeight: 37,
        },
    },
    {
        name: 'display2',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        textTransform: 'uppercase',
        fontSize: 35,
        lineHeight: 40,
        letterSpacing: 0,
        tabletStyle: {
            fontSize: 27,
            lineHeight: 32,
        },
        mobileStyle: {
            fontSize: 24,
            lineHeight: 26,
        },
    },
    {
        name: 'display3',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 25,
        lineHeight: 30,
        letterSpacing: 0,
        textTransform: 'uppercase',
        tabletStyle: {
            fontSize: 20,
            lineHeight: 25,
        },
        mobileStyle: {
            fontSize: 22,
            lineHeight: 27,
        },
    },
    {
        name: 'display4',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        textTransform: 'uppercase',
        fontSize: 20,
        lineHeight: 25,
        letterSpacing: 0,
        tabletStyle: {
            fontSize: 15,
            lineHeight: 20,
        },
        mobileStyle: {
            fontSize: 20,
            lineHeight: 22,
        },
    },
    {
        name: 'caption',
        fontFamily: 'regular',
        fontWeight: 'Regular',
        fontSize: 12,
        lineHeight: 15,
        tabletStyle: {
            fontSize: 12,
            lineHeight: 13,
        },
        mobileStyle: {
            fontSize: 12,
            lineHeight: 14,
        },
    },
    {
        name: 'bigCampaign',
        fontFamily: 'alternative',

        textTransform: 'none',
        fontWeight: 'Regular',
        fontSize: 60,
        lineHeight: 65,
        letterSpacing: 1,
        paddingTop: 24,
        tabletStyle: {
            fontSize: 35,
            lineHeight: 40,
            paddingTop: 14,
        },
        mobileStyle: {
            fontSize: 40,
            lineHeight: 45,
            paddingTop: 18,
        },
    },
    {
        name: 'smallCampaign',
        fontFamily: 'alternative',

        textTransform: 'none',
        fontWeight: 'Regular',
        fontSize: 40,
        lineHeight: 45,
        letterSpacing: 1,

        paddingTop: 18,
        tabletStyle: {
            fontSize: 15,
            lineHeight: 20,
        },
        mobileStyle: {
            fontSize: 20,
            lineHeight: 25,
        },
    },
    {
        name: 'link',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 18,
        lineHeight: 26,
        textDecoration: 'underline',
        tabletStyle: {
            fontSize: 15,
            lineHeight: 23,
        },
        mobileStyle: {
            fontSize: 18,
            lineHeight: 26,
        },
    },
    {
        name: 'body',
        fontFamily: 'regular',
        fontWeight: 'Regular',
        fontSize: 18,
        lineHeight: 26,
        tabletStyle: {
            fontSize: 15,
            lineHeight: 23,
        },
        mobileStyle: {
            fontSize: 18,
            lineHeight: 26,
        },
    },
    {
        name: 'bodySmall',
        fontFamily: 'regular',
        fontWeight: 'Regular',
        fontSize: 14,
        lineHeight: 20,
        tabletStyle: {
            fontSize: 13,
            lineHeight: 19,
        },
        mobileStyle: {
            fontSize: 14,
            lineHeight: 20,
        },
    },
    {
        name: 'button',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 15,
        lineHeight: 20,
        mobileStyle: {
            fontSize: 12,
            lineHeight: 17,
        },
    },
    {
        name: 'productName',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 18,
        lineHeight: 26,

        textTransform: 'inherit',
        letterSpacing: 0,
        tabletStyle: {
            fontSize: 18,
            lineHeight: 20,
        },
        mobileStyle: {
            fontSize: 12,
            lineHeight: 18,
        },
    },
    {
        name: 'productPrice',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 20,
        lineHeight: 25,
        letterSpacing: 0,
        tabletStyle: {
            fontSize: 18,
            lineHeight: 20,
        },
        mobileStyle: {
            fontSize: 15,
            lineHeight: 20,
        },
    },
    {
        name: 'productInfo',
        fontFamily: 'regular',
        fontWeight: 'Regular',
        fontSize: 14,
        lineHeight: 20,
        tabletStyle: {
            fontSize: 12,
            lineHeight: 16,
        },
        mobileStyle: {
            fontSize: 10,
            lineHeight: 11,
        },
    },
    {
        name: 'navigationHeader',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 15,
        lineHeight: 20,
        tabletStyle: {
            fontSize: 15,
            lineHeight: 20,
        },
        mobileStyle: {
            fontSize: 10,
            lineHeight: 12,
        },
    },
    {
        name: 'dropdown',
        fontFamily: 'regular',
        fontWeight: 'Regular',
        fontSize: 18,
        lineHeight: 26,
        tabletStyle: {
            fontSize: 15,
            lineHeight: 23,
        },
        mobileStyle: {
            fontSize: 14,
            lineHeight: 20,
        },
    },
    {
        name: 'sectionLabel',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 15,
        lineHeight: 20,
    },
    {
        name: 'formsLabel',
        fontFamily: 'regular',
        fontSize: 14,
        lineHeight: 20,
    },
    {
        name: 'formsLabelActive',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 10,
        lineHeight: 12,
    },
    {
        name: 'filterButton',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 15,
        lineHeight: 20,
        mobileStyle: {
            fontSize: 15,
            lineHeight: 20,
        },
    },
    {
        name: 'xs',
        fontFamily: 'regular',
        fontWeight: 'Regular',
        fontSize: 10,
        lineHeight: 12,
        mobileStyle: {
            fontSize: 10,
            lineHeight: 12,
        },
        tabletStyle: {
            fontSize: 10,
            lineHeight: 12,
        },
    },
    {
        name: 'small',
        fontFamily: 'regular',
        fontWeight: 'Regular',
        fontSize: 12,
        lineHeight: 14,
        mobileStyle: {
            fontSize: 12,
            lineHeight: 14,
        },
        tabletStyle: {
            fontSize: 12,
            lineHeight: 14,
        },
    },
    {
        name: 'large',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 15,
        lineHeight: 20,
        mobileStyle: {
            fontSize: 15,
            lineHeight: 20,
        },
    },
    {
        name: 'optionList',
        fontFamily: 'regular',
        fontWeight: 'Regular',
        fontSize: 15,
        lineHeight: 20,
        tabletStyle: {
            fontSize: 15,
            lineHeight: 20,
        },
        mobileStyle: {
            fontSize: 15,
            lineHeight: 20,
        },
    },
    {
        name: 'InfoHeader',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 15,
        lineHeight: 20,
        mobileStyle: {
            fontSize: 12,
            lineHeight: 17,
        },
    },
    {
        name: 'mobileMenuImageText',
        fontFamily: 'regular',
        fontWeight: 'Medium',
        fontSize: 15,
        lineHeight: 20,
    },
    {
        name: 'formInput',
        fontFamily: 'regular',
        fontWeight: 'Regular',
        fontSize: 14,
        lineHeight: 20,
        tabletStyle: {
            fontSize: 13,
            lineHeight: 19,
        },
        mobileStyle: {
            fontSize: 16,
            lineHeight: 20,
        },
    },
    {
        name: 'displaySaldoNumber',
        fontFamily: 'regular',
        fontWeight: 'Bold',
        textTransform: 'uppercase',
        fontSize: 80,
        lineHeight: 60,
        letterSpacing: 0,
        mobileStyle: {
            fontSize: 60,
            lineHeight: 65,
        },
    },
);

export type TextVariants = keyof typeof textStyles;

export const serializeTextStyle = (textStyle?: TextStyle) => {
    return textStyle
        ? {
              ...textStyle.desktop,
              [mq('sm', 'md')]: {
                  ...textStyle.tablet,
              },
              [mq('xs', 'sm')]: {
                  ...textStyle.mobile,
              },
              [mq(0, 'xs')]: {
                  ...textStyle.xSmall,
              },
          }
        : {};
};
